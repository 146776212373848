document.onscroll = function () {
    let element = document.getElementById('trigger');
    if (element) {
        let topPos = element.getBoundingClientRect().top;
        if (topPos < window.innerHeight && window.innerWidth < 768) {
            document.getElementById('footer-value').classList.remove('hidden');
            document.getElementById('total').classList.remove('fixed', 'drop-shadow-top-md');
            document.getElementById('coupon').classList.remove('hidden');
            const footerExtras = document.getElementById('footer-extras')
            if (footerExtras) {
                footerExtras.classList.remove('hidden');
            }
        }
        else if (topPos > window.innerHeight && window.innerWidth < 768) {
            document.getElementById('footer-value').classList.add('hidden');
            document.getElementById('coupon').classList.add('hidden');
            const footerExtras = document.getElementById('footer-extras')
            if (footerExtras) {
                footerExtras.classList.add('hidden');
            }
            document.getElementById('total').classList.add('fixed', 'drop-shadow-top-md');
        }
    }
}